import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util';

export class DosespotService extends BaseService {
  static async generateDosespotUrl (id) {
    try {
      const response = await this.request({ auth: true }).get(`/dosespot/patient/${id}`);
      return new ResponseWrapper(response, response.data.body)
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async searchDosespotPharmacy (name = '', state = '', city = '', address = '', zip = '') {
    try {
      const response = await this.request({ auth: true }).get(`/dosespot/pharmacy?city=${city}&state=${state}&name=${name}&address=${address}&zip=${zip}`);
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}